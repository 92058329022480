import Image from 'next/image';

const FooterLoginComp = () => {
  return (
    <div className="footer-login">
      <Image src={'/logo.svg'} layout="fixed" width={202} height={48} alt="PM_footer_logo" />
    </div>
  );
};

export default FooterLoginComp;

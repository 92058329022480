import { Button } from 'antd';
import { FC, useEffect, useState } from 'react';
import { CustomButtonProps } from '../../customTypes';
import { useGetSignedUserDataLazyQuery, useSignInBusinessUserWithFacebookMutation } from '../../../graphql/types';
import { useRouter } from 'next/router';

declare global {
  interface Window {
    FB: any;
  }
}

const ButtonComp: FC<CustomButtonProps> = ({ title, isFB, bg, cl, boderCl, isLoading, ...props }) => {
  const [facebookLogin] = useSignInBusinessUserWithFacebookMutation();
  const router = useRouter();
  const [isFacebookLoading, setIsFacebookLoading] = useState(false);
  const [getSignedInUser] = useGetSignedUserDataLazyQuery();
  useEffect(() => {}, [isFacebookLoading]);

  const handleFacebookLogin = async () => {
    setIsFacebookLoading(true);
    if (window.FB) {
      await window.FB.init({
        appId: '1816511601964921',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v14.0',
      });
      await window.FB.login(
        async res => {
          if (res && res.status === 'connected') {
            await facebookLogin({
              variables: {
                accessToken: res.authResponse.accessToken,
              },
            })
              .then(async data => {
                if (data.data) {
                  localStorage.setItem('token', data.data.signInBusinessUserWithFacebook.token);
                  getSignedInUser().then(value => {
                    localStorage.setItem('user_avatar', value.data?.signedInUser?.profilePicURL || '');
                    localStorage.setItem('user_name', value.data?.signedInUser?.displayName || value.data?.signedInUser?.fullName || '');

                    if (value.data?.signedInUser?.venueAccess && value.data?.signedInUser?.venueAccess.length > 0) {
                      localStorage.setItem('page_id', value.data?.signedInUser?.venueAccess[0].venue?.id || '');
                      localStorage.setItem('page_name', value.data?.signedInUser?.venueAccess[0].venue?.name || '');
                      router
                        .push('/events')
                        .then(() => {})
                        .catch(() => {});
                    } else {
                      router
                        .push('/onboarding')
                        .then(() => {})
                        .catch(() => {});
                    }
                  });
                }
              })
              .catch(() => {});
          }
        },
        { scope: 'email, pages_show_list' },
      );

      setIsFacebookLoading(false);
    } else {
      alert('fb-sdk undefined');
    }
  };

  return (
    <Button
      icon={
        isFB ? (
          <svg width="11" height="17" style={{ marginRight: 12 }} viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.3528 6.13577H6.98031V4.62368C6.96921 4.51326 6.98145 4.40174 7.01623 4.29636C7.05102 4.19098 7.10757 4.09409 7.18222 4.01197C7.25687 3.92986 7.34795 3.86436 7.44955 3.81971C7.55115 3.77507 7.661 3.75229 7.77197 3.75285H10.147V0.586182H6.71906C3.60781 0.586182 3.02197 2.96118 3.02197 4.43368V6.13577H0.646973V9.30243H3.02197V18.4066H6.98031V9.30243H10.0282L10.3528 6.13577Z"
              fill="white"
            />
          </svg>
        ) : (
          <></>
        )
      }
      loading={isLoading || isFacebookLoading}
      className="custom-button"
      style={{
        backgroundColor: bg ? bg : undefined,
        color: cl ? cl : undefined,
        borderColor: boderCl ? boderCl : undefined,
      }}
      onClick={() => {
        setIsFacebookLoading(true);

        if (isFB) {
          handleFacebookLogin();
        }
      }}
      {...props}>
      {title}
    </Button>
  );
};

export default ButtonComp;

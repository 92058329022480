const LeftSVG = () => {
  return (
    <div className="svg-container left">
      <svg width="286" height="440" viewBox="0 0 286 440" fill="none" xmlns="http://www.w3.org/2000/svg">
        {' '}
        <path
          d="M-38.1477 166.807L-38.1467 166.799L-38.1457 166.791L-38.138 166.723C-38.138 166.723 -38.138 166.723 -38.138 166.723C-37.731 163.19 -35.0085 159.645 -31.0503 157.361C-27.0918 155.077 -22.6605 154.495 -19.4014 155.91L198.265 250.396C199.576 250.965 200.23 251.599 200.582 252.172C200.938 252.751 201.161 253.556 201.111 254.77C201.005 257.313 199.408 261.495 193.988 264.624L105.44 315.747C91.9529 323.534 86.913 340.476 94.5354 353.678C102.158 366.881 119.35 370.987 132.838 363.2L221.385 312.077C243.524 299.295 256.459 277.561 257.385 255.293C258.327 232.629 246.546 210.185 222.374 199.692L4.70806 105.206C-37.1235 87.0473 -88.8163 116.854 -94.0902 162.135C-94.0905 162.137 -94.0908 162.139 -94.091 162.141L-102.803 234.262C-104.651 249.56 -93.5096 262.961 -78.0529 264.226C-62.6821 265.484 -48.7138 254.274 -46.8772 239.071L-38.1559 166.875L-38.1558 166.875L-38.1477 166.807Z"
          stroke="#FF8500"
          strokeWidth="6"></path>{' '}
      </svg>
      <svg width="289" height="227" viewBox="0 0 289 227" fill="none" xmlns="http://www.w3.org/2000/svg">
        {' '}
        <path
          d="M192.435 201.5C192.435 261.243 141.987 309.877 79.4999 309.877C17.0134 309.877 -33.4345 261.243 -33.4345 201.5C-33.4345 141.757 17.0134 93.1231 79.4999 93.1231C141.987 93.1231 192.435 141.757 192.435 201.5ZM79.4999 400C193.432 400 286 311.241 286 201.5C286 91.7592 193.432 3 79.4999 3C-34.4323 3 -127 91.7592 -127 201.5C-127 311.241 -34.4323 400 79.4999 400Z"
          stroke="#FEC400"
          strokeWidth="6"></path>{' '}
      </svg>
    </div>
  );
};

export default LeftSVG;

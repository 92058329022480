import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import LeftSVG from './LeftSVG';

import RightSVG from './RightSVG';
import ButtonComp from './Button';
import FooterLoginComp from './Footer';
import Link from 'next/link';

interface LoginWrapperProps {
  children?: React.ReactNode;
  isRegister?: boolean;
  isResetPass?: boolean;
}

const LoginWrapper: FC<LoginWrapperProps> = ({ children, isRegister, isResetPass }) => {
  const { t } = useTranslation('page');

  return (
    <div className="login-container">
      <LeftSVG />
      <div className="inner-container">
        {isResetPass ? (
          <></>
        ) : (
          <>
            <h1 className="title">{t('login.title')}</h1>
            <h2 className="subtitle">
              {isRegister ? t('register.subtitle') : t('login.subtitle1')}{' '}
              <Link href={!isRegister ? '/register' : '/login/with-email'} passHref>
                <span>{isRegister ? t('register.subtitle2') : t('register.subtitle1')}</span>
              </Link>
            </h2>
            <ButtonComp cl="#fff" boderCl="#000" bg="#000" title={isRegister ? t('register.withFB') : t('login.withFB')} isFB isLoading={false} />
          </>
        )}

        {children}

        {isResetPass ? (
          <h2 className="subtitle">
            {t('login.subtitle1')}{' '}
            <Link href={!isRegister ? '/register' : '/login/with-email'} passHref>
              <span>{isRegister ? t('register.subtitle2') : t('register.subtitle1')}</span>
            </Link>
          </h2>
        ) : (
          <></>
        )}

        <FooterLoginComp />
      </div>
      <RightSVG />
    </div>
  );
};

export default LoginWrapper;

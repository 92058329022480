import { NextPage } from 'next';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import LoginLayout from '../../components/login/Layout';
import Script from 'next/script';

const Login: NextPage = () => {
  const { t } = useTranslation('page');

  return (
    <>
      <Script id="fb-script4" async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js" />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
                        window.fbAsyncInit = function() {
                            FB.init({
                                appId: ${process.env.fbAppKey},
                                autoLogAppEvents: true,
                                xfbml: true,
                                version: 'v14.0'
                            })
                        }
                 `,
        }}
        id="fb-script3"
      />
      <LoginLayout>
        <Link href={'/login/with-email'} passHref>
          <a className="withEmail">{t('login.withEmail')}</a>
        </Link>
        <Link href={'/reset'} passHref>
          <a id="forgot-password-button" className="withEmail">
            {t('login.forgotPassword')}
          </a>
        </Link>
      </LoginLayout>
    </>
  );
};

export default Login;

const RightSVG = () => {
  return (
    <div className="svg-container right">
      <svg width="394" height="843" viewBox="0 0 394 843" fill="none" xmlns="http://www.w3.org/2000/svg">
        {' '}
        <circle cx="44" cy="331" r="41" stroke="#FEC400" strokeWidth="6"></circle>{' '}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M447.883 764.849C438.291 774.392 422.778 774.351 413.235 764.759L243.519 594.159C241.097 591.724 237.061 592.08 235.102 594.901L204.839 638.483C186.066 665.517 147.799 669.87 123.434 647.744L38.5604 570.668C28.5434 561.572 27.7974 546.077 36.894 536.06C45.9906 526.043 61.4852 525.297 71.5021 534.394L156.375 611.469C158.834 613.702 162.696 613.263 164.591 610.535L194.854 566.953C214.263 539.002 254.257 535.476 278.257 559.601L447.973 730.201C457.516 739.793 457.476 755.306 447.883 764.849Z"
          stroke="#A64BFF"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"></path>{' '}
        <path
          d="M407.949 153.5C407.949 198.141 370.254 234.531 323.5 234.531C276.746 234.531 239.051 198.141 239.051 153.5C239.051 108.859 276.746 72.4688 323.5 72.4688C370.254 72.4688 407.949 108.859 407.949 153.5ZM323.5 304C409.819 304 480 236.73 480 153.5C480 70.2695 409.819 3 323.5 3C237.181 3 167 70.2695 167 153.5C167 236.73 237.181 304 323.5 304Z"
          stroke="#FF0252"
          strokeWidth="6"></path>{' '}
      </svg>
    </div>
  );
};

export default RightSVG;
